import React from 'react'

const Crypto = () => {

  return (
    <div className='crypto'>
      <div className='first'>
        <div className='second'>
          <iframe className='fourth' src="https://widget.coinlib.io/widget?type=full_v2&theme=light&cnt=100&pref_coin_id=1505&graph=yes" width="100%" height="409px" scrolling="auto" marginwidth="0" marginheight="0" frameborder="0" border="0">
          </iframe>
        </div>
      </div>
    </div>
  )
}


export default Crypto
