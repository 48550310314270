import partner1 from "../assets/partner1.jpg";
import partner2 from "../assets/partner2.jpg";

const Partnership = () => {
    return (
        <div className='partnership'>
            <div className='partnership-header'>
                HOW TO PARTNER WITH US
            </div>
            <div className='partnership-subtext'>
                <div className='ps-text'>
                    We aim at constant and effective development with the support of new investors and partners. Loan offers are only available to Coral Wealth Management members who have deposited on their accounts
                </div>
                <img src={partner1} className='ps-img' alt="" />
                <div className='ps-text'>
                Coral Wealth Management presents Affiliate And Loan/Representative program with attractive business terms within the framework of the project to all its customers around the world. With Our affiliate program, each participant can earn 10% referral commission.
                </div>
                <img src={partner2} className='ps-img' alt="" />
                <div className='ps-text'>
                All you need to do is just send your Referral Link to your family, friends, relatives, colleague, or any other individual of your society and spread the word of mouth about the lucrative investment plans of CWM to start earning additional income without investment.
                </div>
            </div>
        </div>
    )
}

export default Partnership
