import office1 from "../assets/office1.jpg";
import office2 from "../assets/office2.jpg";
import office3 from "../assets/office3.jpg";

const Office = () => {
  return (
    <div className='office-container'>
      <div className='partnership-header'>
        CWM OFFICE
      </div>
      <div className='office-subtext'>
        We are an Investment Agency with the aim of supporting people all over the world to become independent entrepreneurs.
      </div>
      <div className='office-item'>
        <img src={office1} alt="" />
        <p className='oi-header'>BUSINESS OPPORTUNITY</p>
        <p className='oi-text'>Our business model has established itself all over the world and has already enabled thousands of people to successfully enter the entrepreneurship. With Coral Wealth Management you now have the opportunity to become successful with your own company!</p>
      </div>
      <div className='office-item'>
        <img src={office2} alt="" />
        <p className='oi-header'>COACHING AND MENTORING</p>
        <p className='oi-text'>We support you in your professional and personal development through an all-encompassing mentoring offering in the form of regular business coaching sessions and a series of exclusive webinars. You will receive the late-breaking marketing materials for your business appointments at all time in the CWM Media Center.</p>
      </div>
      <div className='office-item'>
        <img src={office3} alt="" />
        <p className='oi-header'>INVESTMENT MANAGEMENT</p>
        <p className='oi-text'>Thanks to our great investment packages, which fascinate and motivate people all around the world, you as a CWM Investor are always up-to-date with the current industry development and you receive first-hand information on how to become successful investing in a risk-free platform</p>
      </div>
    </div>
  )
}

export default Office
