import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Announcement from '../components/Announcement'
import CompleteWithdrawal from '../components/CompleteWithdrawal'
import ConfirmPassword from '../components/ConfirmPassword'
import CryptoSlide from '../components/CryptoSlide'
import Dashboard from '../components/Dashboard'
import FourZeroFour from '../components/FourZeroFour'
import FAQ from '../components/FAQ'
import Footer from '../components/Footer'
import Fund from '../components/Fund'
import History from '../components/History'
import LoggedNavbar from '../components/LoggedNavbar'
import Navbar from '../components/Navbar'
import RequestWithdrawal from '../components/RequestWithdrawal'
import Subscribe from '../components/Subscribe'
import Support from '../components/Support'
import Whatsapp from '../components/Whatsapp'
import PaymentSuccessful from '../components/PaymentSuccessful'

const Logged = () => {

  return (
    <div>
      <LoggedNavbar />
      <Whatsapp />
      <Routes>
        <Route path='*' element={<FourZeroFour />} />
        <Route path='/pay/:amount/:wallet' element={<PaymentSuccessful />} />
        <Route path='/user/dashboard' element={<Dashboard />} />
        <Route path='/user/fund' element={<Fund />} />
        <Route path='/user/confirm-password' element={<ConfirmPassword />} />
        <Route path='/user/request-withdrawal' element={<RequestWithdrawal />} />
        <Route path='/user/complete-withdrawal' element={<CompleteWithdrawal />} />
        <Route path='/user/subscribe' element={<Subscribe />} />
        <Route path='/user/support' element={<Support />} />
        <Route path='/user/history' element={<History />} />
        <Route path='/user/faq' element={<FAQ />} />
      </Routes>
      <Footer />
      <CryptoSlide />
    </div>
  )
}

export default Logged
