import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { client } from '../client';
import { userQuery } from '../utils/data';
import { fetchUser } from '../utils/fetchUser';
import Crypto from './Crypto';

const Dashboard = () => {

    const navigate = useNavigate()

    const username = JSON.parse(localStorage.getItem('user')).username
    const query = userQuery(username)

    client.fetch(query)
        .then((data) => {
            localStorage.setItem('user', JSON.stringify(data[0]))
        })

    const user = JSON.parse(localStorage.getItem('user'))


    return (
        <div className='dashboard'>
            <p className='username'>Welcome <span>{user.surname}</span></p>
            <p className='welcome-msg'>
                Welcome to CWM. Fund your account and trade now
            </p>
            <div className='account-info-container'>
                <div className='account-info'>
                    <div className='main-info'>
                        <div className="balance">
                            <p className='amount'>${user.balance}.00</p>
                            <p className='subtext'>Account Balance</p>
                        </div>
                        <MonetizationOnOutlinedIcon style={{ fontSize: "2rem" }} />
                    </div>
                    <p className='account-link'><a href="/user/history">Trade history</a></p>
                </div>
                <div className='account-info'>
                    <div className='main-info'>
                        <div className="balance">
                            <p className='amount'>${user.profit}.00</p>
                            <p className='subtext'>Total Profit</p>
                        </div>
                        <PaymentsOutlinedIcon style={{ fontSize: "2rem" }} />
                    </div>
                    <p className='account-link'><a href="/user/subscribe">Invest now</a></p>
                </div>
                <div className='account-info'>
                    <div className='main-info'>
                        <div className="balance">
                            <p className='amount'>${user.referral}.00</p>
                            <p className='subtext'>Total Referral Bonus</p>
                        </div>
                        <RepeatOutlinedIcon style={{ fontSize: "2rem" }} />
                    </div>
                    <p className='account-link'><a href="/user/history">View Referrals</a></p>
                </div>
                <div className='account-info'>
                    <div className='main-info'>
                        <div className="balance">
                            <p className='amount'>${user.deposit}.00</p>
                            <p className='subtext'>Total Deposit</p>
                        </div>
                        <SaveAltOutlinedIcon style={{ fontSize: "2rem" }} />
                    </div>
                    <p className='account-link'><a href="/user/fund">Make Deposit</a></p>
                </div>
                <div className='current-plan-container'>
                    <div className="current-plan">
                        <div className='cp-info'>
                            <p>Current Plan:</p>
                            <p>Amount Deposited:</p>
                            <p>Completed In:</p>
                        </div>

                        <div className='cp-value'>
                            <p>{user.plan}</p>
                            <p>${user.planAmount}</p>
                            <p>{user.planDaysLeft} DAYS</p>
                        </div>
                    </div>
                </div>
            </div>
            <Crypto />
        </div>
    )
}






export default Dashboard
