import realEstate from "../assets/estate.jpg";
import forex from "../assets/forex.jpg";
import gold from "../assets/gold.jpg";

const Services = () => {
    return (
        <div className="section">
            <div className="services-container">
                <div className="section-hero-text">
                    SERVICES
                </div>
                <div className="services-subtext-container">
                    We are an international financial company engaged in investment activities, which are related to trading on financial markets and cryptocurrency exchanges, real estate investment, stock and gold. All performed by qualified professional traders.
                </div>
                <div className="service-gallery">
                    <div className="service-img-container">
                        <div className="service-img">
                            <img src={realEstate} alt="" />
                        </div>
                        <div className="service-img-subtext">
                            Real Estate
                        </div>
                    </div>
                    <div className="service-img-container">
                        <div className="service-img">
                            <img src={forex} alt="" />
                        </div>
                        <div className="service-img-subtext">
                            Forex
                        </div>
                    </div>
                    <div className="service-img-container">
                        <div className="service-img">
                            <img src={gold} alt="" />
                        </div>
                        <div className="service-img-subtext">
                            Gold
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
