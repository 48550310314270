import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import About from '../components/About'
import BuyCoins from '../components/BuyCoins'
import Contact from '../components/Contact'
import Crypto from '../components/Crypto'
import FAQ from '../components/FAQ'
import Invest from '../components/Invest'
import Office from '../components/Office'
import Partners from '../components/Partners'
import Partnership from '../components/Partnership'
import Ratings from '../components/Ratings'
import Services from '../components/Services'
import Slider from '../components/Slider'

const NotLoggged = () => {
    return (
        <div>
            <Slider />
            <About />
            <Services />
            <Partners />
            <Ratings />
            <Crypto />
            <BuyCoins />
            <Invest />
            <Partnership />
            <Office />
            <FAQ />
            <Contact />
        </div>
    )
}

export default NotLoggged
