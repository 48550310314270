import "./style.css"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from "react";

const FAQ = () => {

    const [ans1, setAns1] = useState(false)
    const [ans2, setAns2] = useState(false)
    const [ans3, setAns3] = useState(false)
    const [ans4, setAns4] = useState(false)


    const toggleAnswer1 = () => {
        if (ans1) {
            setAns1(false)
        } else {
            setAns1(true)
        }
    }

    const toggleAnswer2 = () => {
        if (ans2) {
            setAns2(false)
        } else {
            setAns2(true)
        }
    }

    const toggleAnswer3 = () => {
        if (ans3) {
            setAns3(false)
        } else {
            setAns3(true)
        }
    }

    const toggleAnswer4 = () => {
        if (ans4) {
            setAns4(false)
        } else {
            setAns4(true)
        }
    }

    return (
        <div className='faq'>
            <div className="faq-container">
                <p className="faq-header">Frequently Asked <span>Questions</span></p>
                <p className="faq-sub">We got answers to the questions that you might want to ask about CWM</p>

                <div className="question">
                    <div className="q-question"><p>How can I get started</p><span onClick={toggleAnswer1}><ArrowDropDownIcon /></span></div>
                    <div className={ans1 ? "show-answer" : "q-answer"}>Registration process is very easy and will take a few moments to complete Simply click START EARNING button on the first section and fill in all the required fields</div>
                    <hr />
                </div>

                <div className="question">
                    <div className="q-question"><p>How do I make deposit</p><span onClick={toggleAnswer2}><ArrowDropDownIcon /></span></div>
                    <div className={ans2 ? "show-answer" : "q-answer"}>Login to your account and click on make deposit. Select the coin you wish to deposit with, fill out the form and make payment to the company's address displayed.</div>
                    <hr />
                </div>

                <div className="question">
                    <div className="q-question"><p>How do I make a withdrawal</p><span onClick={toggleAnswer3}><ArrowDropDownIcon /></span></div>
                    <div className={ans3 ? "show-answer" : "q-answer"}>Click on the withdraw menu in the user dashboard side menu and follow the prompt</div>
                    <hr />
                </div>

                <div className="question">
                    <div className="q-question"><p>How long does it take to process my withdrawal</p><span onClick={toggleAnswer4}><ArrowDropDownIcon /></span></div>
                    <div className={ans4 ? "show-answer" : "q-answer"}>Once your withdrawal request is recieved, it is processed immediately and money is sent to your wallet within minutes</div>
                    <hr />
                </div>
            </div>
        </div>
    )
}

export default FAQ