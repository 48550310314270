import "./style.css";

const CompleteWithdrawal = () => {
    return (
        <div className='complete-withdrawal'>
            <p className="cw-header">REQUEST FOR WITHDRAWAL</p>
            <div className="cw-container">
                <p className="chosen-coin">You have chosen Bitcoin</p>
                <div className="cw-amount">
                    <p className="cw-amount">Enter amount to withdraw<span>* </span>:</p>
                    <input type="number" placeholder="Enter Amount" />
                    <button>WITHDRAW</button>
                </div>
            </div>
        </div>
    )
}

export default CompleteWithdrawal
