import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";

const Support = () => {

    const navigate = useNavigate()
    const [mail, setMail] = useState(false)
    const [body, setBody] = useState('') 

    const message = (val) => {
        setBody(val.target.value)
    }

    const sendMail = () => {
        setMail(true)
        setTimeout(function() {
            setMail(false)
            navigate('/')
        }, 3000)
    }


    return (
        <div className="support">
        <p className="support-header">CWT Support</p>
            <div className="support-container">
                <p className="support-text">For inquiries, suggestions or complaints, mail us at:</p>
                <a href="">support@cwt.com</a>
                <p className="message">Message<span>*</span></p>
                <textarea onChange={message} name="message" placeholder="Message" cols="30" rows="10"></textarea>
                <p className={mail ? 'help-text' : 'collapse'}>We have recieved your complaint and will attend to you as soon as possible. Thank you.</p>
                <button onClick={sendMail} className="btn"><a href={`mailto:coralwealth.mt@gmail.com?subject=Customer message&body=${body}`}>SEND</a></button>
            </div>
        </div>
    )
}

export default Support
