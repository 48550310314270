import "./style.css"
import usdt from "../assets/usdt.png"
import eth from "../assets/ethereum.png"
import btc from "../assets/bitcoin.png"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { userQuery } from "../utils/data"
import { client } from "../client"

const RequestWithdrawal = () => {

    const navigate = useNavigate()
    const username = JSON.parse(localStorage.getItem('user')).username
    const query = userQuery(username)

    client.fetch(query)
        .then((data) => {
            localStorage.setItem('user', JSON.stringify(data[0]))
        })

    const user = JSON.parse(localStorage.getItem('user'))

    const bal = user.balance
    var balance = (bal == undefined ? 0 : parseFloat(bal.replaceAll(',', '')))


    const [usdtvalue, setUsdtValue] = useState(0)
    const [ethvalue, setEthValue] = useState(0)
    const [btcvalue, setBtcValue] = useState(0)




    const [usdtFundErr, setUsdtFundErr] = useState(null)
    const [usdtRangeErr, setUsdtRangeErr] = useState(null)
    const [usdtSuccess, setUsdtSuccess] = useState()
    const [usdtWall, setUsdtWall] = useState('')
    const [usdtWallErr, setUsdtWallErr] = useState(null)

    const [ethFundErr, setEthFundErr] = useState(null)
    const [ethRangeErr, setEthRangeErr] = useState(null)
    const [ethSuccess, setEthSuccess] = useState()
    const [ethWall, setEthWall] = useState('')
    const [ethWallErr, setEthWallErr] = useState(null)

    const [btcFundErr, setBtcFundErr] = useState(null)
    const [btcRangeErr, setBtcRangeErr] = useState(null)
    const [btcSuccess, setBtcSuccess] = useState()
    const [btcWall, setBtcWall] = useState('')
    const [btcWallErr, setBtcWallErr] = useState(null)

    const setwdUsdtValue = (val) => {
        setUsdtValue(val.target.value)
    }

    const setwdEthValue = (val) => {
        setEthValue(val.target.value)
    }

    const setwdBtcValue = (val) => {
        setBtcValue(val.target.value)
    }

    const setUsdtWallValue = (val) => {
        setUsdtWall(val.target.value)
    }

    const setEthWallValue = (val) => {
        setEthWall(val.target.value)
    }

    const setBtcWallValue = (val) => {
        setBtcWall(val.target.value)
    }

    const withdrawUsdt = () => {
        if (usdtWall == null || usdtWall.length < 20) {
            setUsdtWallErr(true)
            setUsdtFundErr(null)
            setUsdtRangeErr(null)
            setUsdtSuccess(null)
        } else if (usdtvalue > 100000 || usdtvalue < 100) {
            setUsdtWallErr(null)
            setUsdtFundErr(null)
            setUsdtRangeErr(true)
            setUsdtSuccess(null)
        } else if (usdtvalue > balance) {
            setUsdtWallErr(null)
            setUsdtFundErr(true)
            setUsdtRangeErr(null)
            setUsdtSuccess(null)
        } else {
            setUsdtWallErr(null)
            setUsdtFundErr(null)
            setUsdtRangeErr(null)
            setUsdtSuccess(true)
            balance = balance - usdtvalue
            let repBalance = balance.toLocaleString()
            client.patch(user.username).set({ balance: repBalance }).commit()
            setTimeout(function () {
                navigate(`/pay/${usdtvalue}/${usdtWall}`)
            }, 3000)
        }
    }


    const withdrawEth = () => {
        if (ethWall == null || ethWall.length < 20) {
            setEthWallErr(true)
            setEthFundErr(null)
            setEthRangeErr(null)
            setEthSuccess(null)
        } else if (ethvalue > 100000 || ethvalue < 100) {
            setEthWallErr(null)
            setEthFundErr(null)
            setEthRangeErr(true)
            setEthSuccess(null)
        } else if (ethvalue > balance) {
            setEthWallErr(null)
            setEthFundErr(true)
            setEthRangeErr(null)
            setEthSuccess(null)
        } else {
            setEthWallErr(null)
            setEthFundErr(null)
            setEthRangeErr(null)
            setEthSuccess(true)
            balance = balance - ethvalue
            let repBalance = balance.toLocaleString()
            client.patch(user.username).set({ balance: repBalance }).commit()
            setTimeout(function () {
                navigate(`/pay/${ethvalue}/${ethWall}`)
            }, 3000)
        }
    }


    const withdrawBtc = () => {
        if (btcWall == null || btcWall.length < 20) {
            setBtcWallErr(true)
            setBtcFundErr(null)
            setBtcRangeErr(null)
            setBtcSuccess(null)
        } else if (btcvalue > 100000 || btcvalue < 100) {
            setBtcWallErr(null)
            setBtcFundErr(null)
            setBtcRangeErr(true)
            setBtcSuccess(null)
        } else if (btcvalue > balance) {
            setBtcWallErr(null)
            setBtcFundErr(true)
            setBtcRangeErr(null)
            setBtcSuccess(null)
        } else {
            setBtcWallErr(null)
            setBtcFundErr(null)
            setBtcRangeErr(null)
            setBtcSuccess(true)
            balance = balance - btcvalue
            let repBalance = balance.toLocaleString()
            client.patch(user.username).set({ balance: repBalance }).commit()
            setTimeout(function () {
                navigate(`/pay/${btcvalue}/${btcWall}`)
            }, 3000)
        }
    }



    return (
        <div className='request-withdrawal'>
            <div className="rw-container">
                <p>REQUEST WITHDRAWAL</p>

                <div className="rw-coins">
                    <div className="rw-coin">
                        <div className="rw-coin-name">
                            <img src={usdt} alt="" />
                            <p>USDT</p>
                        </div>

                        <div className="rw-desc">
                            <div className="rw-desc-name">
                                <p>Minimum Amount:</p>
                                <p>Maximum Amount:</p>
                                <p>Charge Type:</p>
                                <p>Charge Amount:</p>
                                <p>Duration:</p>
                            </div>

                            <div className="rw-desc-value">
                                <p>$100</p>
                                <p>$100,000</p>
                                <p>Percentage</p>
                                <p>10%</p>
                                <p>Instant</p>
                            </div>
                        </div>

                        <input onChange={setwdUsdtValue} className="withdraw-input" type="number" />
                        <p className="withdraw-text">Amount</p>

                        <input onChange={setUsdtWallValue} className="withdraw-input" type="text" name="" id="" />
                        <p className="withdraw-text">Wallet Address</p>

                        <p className={usdtFundErr ? 'wd-error' : 'wd-col'}>Insufficient funds!</p>
                        <p className={usdtWallErr ? 'wd-error' : 'wd-col'}>Please check wallet address</p>
                        <p className={usdtRangeErr ? 'wd-error' : 'wd-col'}>Range $100 to $100,000!</p>
                        <p className={usdtSuccess ? 'wd-success' : 'wd-col'}>Sucesss. Withdrawal processing!</p>

                        <button className="btn" onClick={withdrawUsdt}>Request Withdrawal</button>

                    </div>

                    <div className="rw-coin">
                        <div className="rw-coin-name">
                            <img src={eth} alt="" />
                            <p>Ethereum</p>
                        </div>

                        <div className="rw-desc">
                            <div className="rw-desc-name">
                                <p>Minimum Amount:</p>
                                <p>Maximum Amount:</p>
                                <p>Charge Type:</p>
                                <p>Charge Amount:</p>
                                <p>Duration:</p>
                            </div>

                            <div className="rw-desc-value">
                                <p>$100</p>
                                <p>$100,000</p>
                                <p>Percentage</p>
                                <p>10%</p>
                                <p>Instant</p>
                            </div>
                        </div>

                        <input onChange={setwdEthValue} className="withdraw-input" type="number" />
                        <p className="withdraw-text">Amount</p>

                        <input onChange={setEthWallValue} className="withdraw-input" type="text" name="" id="" />
                        <p className="withdraw-text">Wallet Address</p>

                        <p className={ethFundErr ? 'wd-error' : 'wd-col'}>Insufficient funds!</p>
                        <p className={ethWallErr ? 'wd-error' : 'wd-col'}>Please check wallet address</p>
                        <p className={ethRangeErr ? 'wd-error' : 'wd-col'}>Range $100 to $100,000!</p>
                        <p className={ethSuccess ? 'wd-success' : 'wd-col'}>Sucesss. Withdrawal processing!</p>

                        <button className="btn" onClick={withdrawEth}>Request Withdrawal</button>

                    </div>

                    <div className="rw-coin">
                        <div className="rw-coin-name">
                            <img src={btc} alt="" />
                            <p>Bitcoin</p>
                        </div>

                        <div className="rw-desc">
                            <div className="rw-desc-name">
                                <p>Minimum Amount:</p>
                                <p>Maximum Amount:</p>
                                <p>Charge Type:</p>
                                <p>Charge Amount:</p>
                                <p>Duration:</p>
                            </div>

                            <div className="rw-desc-value">
                                <p>$100</p>
                                <p>$100,000</p>
                                <p>Percentage</p>
                                <p>10%</p>
                                <p>Instant</p>
                            </div>
                        </div>

                        <input onChange={setwdBtcValue} className="withdraw-input" type="number" />
                        <p className="withdraw-text">Amount</p>

                        <input onChange={setBtcWallValue} className="withdraw-input" type="text" name="" id="" />
                        <p className="withdraw-text">Wallet Address</p>

                        <p className={btcFundErr ? 'wd-error' : 'wd-col'}>Insufficient funds!</p>
                        <p className={btcWallErr ? 'wd-error' : 'wd-col'}>Please check wallet address</p>
                        <p className={btcRangeErr ? 'wd-error' : 'wd-col'}>Range $100 to $100,000!</p>
                        <p className={btcSuccess ? 'wd-success' : 'wd-col'}>Sucesss. Withdrawal processing!</p>

                        <button className="btn" onClick={withdrawBtc}>Request Withdrawal</button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestWithdrawal
