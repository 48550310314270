import React from 'react'
import "./style.css"

const Announcement = () => {
  return (
    <div className='announcementContainer'>
      coralwealth.mt@gmail.com
    </div>
  )
}

export default Announcement
