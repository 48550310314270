import LoggedNavbar from "../components/LoggedNavbar"
import "../components/style.css"
import CryptoSlide from '../components/CryptoSlide'
import Footer from '../components/Footer'
import NotLoggged from './NotLoggged'
import Whatsapp from '../components/Whatsapp'

const Home = () => {
    return (
        <div className='home'>
            <Whatsapp />
            <LoggedNavbar />
            <NotLoggged />
            <Footer />
            <CryptoSlide />
        </div>
    )
}

export default Home
