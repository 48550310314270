import "./style.css";
import img1 from "../assets/img1.jpg";
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import { useState } from "react";

const Slider = () => {

    const [slideIndex, setSlideIndex] = useState(0);

    const handleClick = (direction) => {
        if (direction === "left") {
            setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 1)
        } else {
            setSlideIndex(slideIndex < 1 ? slideIndex + 1 : 0)
        }
    };

    return (
        <div className="slide-container">
            <div className="slide-wrapper" style={{ transform: `translateX(${slideIndex * -100}vw)` }}>
                <div className="slide">
                    <div className="slide-text">
                        <p className="hero-text">The safest place to <span>earn</span> with your crypto</p>
                        <p className="hero-subtext">We place a high value on further education and the personal growth of all our employees</p>
                    </div>
                    <div className="slide-btn">
                        <button><a href="/user/subscribe">START EARNING</a></button>
                    </div>
                    <div className="slideImg-container">
                        <img className="slide-img" src={img1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Slider
