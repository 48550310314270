import travelWorld from "../assets/travelworld.svg"
import myWorld from "../assets/myworld.svg"
import eliteClub from "../assets/eliteclub.svg"

const Partners = () => {
    return (
        <div className="section">
            <div className="partners-container">
                <div className="section-hero-text">
                    PARTNERS
                </div>
                <div className="partners-subtext-container">
                    We are an international financial company engaged in investment activities, which are related to trading on financial markets and cryptocurrency exchanges, real estate investment, stock and gold. All performed by qualified professional traders.
                </div>
                <div className="partners-gallery">
                    <div className="partners-img-container">
                        <div className="partners-img">
                            <img src={travelWorld} alt="" />
                        </div>
                        <div className="service-img-subtext">
                            Thanks to travelWorld, Coral Wealth Management investors have access to a travel booking platform that impresses with attractive prices and the direct cooperation with world-renowned providers.
                        </div>
                    </div>
                    <div className="partners-img-container-one">
                        <div className="partners-img">
                            <img src={myWorld} alt="" />
                        </div>
                        <div className="service-img-subtext">
                            The myWorld Benefit Program offers unique shopping benefits to more than 15 million customers in over 50 countries as well as innovative customer loyalty programs for about 150,000 myWorld partners.
                        </div>
                    </div>
                    <div className="partners-img-container">
                        <div className="partners-img">
                            <img src={eliteClub} alt="" />
                        </div>
                        <div className="service-img-subtext">
                            The EliteClub provides Coral Wealth Management investors the opportunity to join a premium business & lifestyle club for entrepreneurial thinkers and high-level personalities from all around the world.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partners
