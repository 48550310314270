import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { client } from "../client";
import { userQuery } from "../utils/data";
import "./style.css";

const Login = () => {

  const navigate = useNavigate();

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [user, setUser] = useState('')


  const uName = (val) => {
    setUsername(val.target.value)
  }

  const pass = (val) => {
    setPassword(val.target.value)
  }

  const [credError, setCredError] = useState(false)
  const [passError, setPassError] = useState(false)


  const logIn = () => {
    const query = userQuery(username)
    client.fetch(query)
      .then((data) => {
        if (data.length == 0) {
          setPassError(false)
          setCredError(true)
        } else if (password != data[0].password) {
          setPassError(true)
          setCredError(false)
        } else if (password == data[0].password) {
          setUser(data)
          localStorage.setItem('user', JSON.stringify(data[0]))
          console.log('login successful')
          navigate('/user/dashboard')
        }
      })
  }


  return (
    <div className='login'>
      <div className="login-container">

        <p className="login-header">LOGIN</p>

        <div className="login-errors">
          <p className={credError ? 'show-error' : ''}>Invalid Credentials</p>
          <p className={passError ? 'show-error' : ''}>Incorrect Password</p>
        </div>

        <p className="login-username">Username/Email</p>
        <input className="lu-value" onChange={uName} type="text" />

        <p className="login-pass">Password</p>
        <input className="lp-value" onChange={pass} type="text" />

        <button onClick={logIn}>Login</button>

        <p className="login-signin"><a href="/sign-up">Sign Up</a></p>
      </div>
    </div>
  )
}

export default Login
