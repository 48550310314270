
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import FourZeroFour from "./components/FourZeroFour";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Home from "./containers/Home";
import Logged from "./containers/Logged";
import { fetchUser } from "./utils/fetchUser";

const App = () => {

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const user = fetchUser()


    if(!user) {
      if (location.pathname == '/sign-up') {
        navigate('/sign-up')
      } else {
        navigate('/login')
      }
    }
  }, [])


  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/" element={<Home />} />
      <Route path="/*" element={<Logged />} />
      <Route path="*" element={<FourZeroFour />} />
    </Routes>
    // <Home />
    // <Logged />
  );
}

export default App;
