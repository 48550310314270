import "./style.css"

const About = () => {
  return (
    <div className='section'>
      <div className='about-container'>
        <div className='section-hero-text'>
          ABOUT US
        </div>
        <div className="section-subtext-container">
          <div className="about-subtext">Coral Wealth Management has proven to be crisis resistant as its completely handled online and doesn't depend on human to human interaction. A profit generating investment business that works online, is not dependent on the economy and builds infrastructure for the new economy of tomorrow.A projected 5-20% pure profit – paid out every week or month, and with an interesting twist on our side; Should the yield drop below 7% for 7 consecutive days, your capital will be returned along with any accrued profits (if compounded). No fuss – no quibbling!</div>
          <div className="about-subtext">Recently, our company made a successful attempt the international technology market with an offer of profitable investments in financial investments. Our main aim is to provide safe and secured returns to our investors with effective and profitable trading and mining solutions where investors need little or no experience at all about the same. Our investors can choose one of three lucrative yet simple investment plan with Coral Wealth Management. If you want to join us, to earn over a period of long time then all you need to do is to register on our website, make deposit and sit back and relax while our experts work for you. We are expanding our features by providing you instant deposit and withdrawals. Use the possibilities of the company and earn on daily basis.</div>
        </div>
      </div>
    </div>
  )
}

export default About
