import usdt from "../assets/usdt.png";
import eth from "../assets/ethereum.png";
import btc from "../assets/bitcoin.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../utils/fetchUser";
import { client } from "../client";
import { addressQuery } from "../utils/data";

const Fund = () => {

  const navigate = useNavigate()

  const user = fetchUser()


  const ethQuery = addressQuery('ETH')
  const usdtQuery = addressQuery('USDT')
  const btcQuery = addressQuery('BTC')

  const [ethAddr, setEthAddr] = useState('')
  const [btcAddr, setBtcAddr] = useState('')
  const [usdtAddr, setUsdtAddr] = useState('')

  client.fetch(ethQuery)
    .then((data) => {
      setEthAddr(data[0].address)
    })

  client.fetch(usdtQuery)
    .then((data) => {
      setUsdtAddr(data[0].address)
    })

  client.fetch(btcQuery)
    .then((data) => {
      setBtcAddr(data[0].address)
    })


  const [usdtcoin, setUsdtcoin] = useState(false)
  const [ethcoin, setEthcoin] = useState(false)
  const [bitcoin, setBitcoin] = useState(false)
  const [selectedAddr, setSelectedAddr] = useState('')
  const [copied, setCopied] = useState(false)
  const [screenshot, setScreenshot] = useState()


  const input = document.getElementById('upload');

  const setSS = (val) => {
    var file = val.target.files[0];
    client.assets
      .upload('image', file, { contentType: file.type, filename: file.name })
      .then((document) => {
        setScreenshot(document);
      })
  }

  const fund = () => {
    const doc = {
      _type: 'uploads',
      user: user.username,
      upload: {
        _type: 'image',
        asset: {
          _type: 'reference',
          _ref: screenshot?._id
        }
      }
    }

    client.create(doc)
    // .then(navigate('/login'))
    setTimeout(function () {
      navigate('/user/dashboard')
    }, 3000)
  }


  const copy = () => {
    navigator.clipboard.writeText(selectedAddr)
    setCopied(true)
    setTimeout(function () {
      setCopied(false)
    }, 5000)
  }

  const usdtActive = () => {
    setUsdtcoin(true)
    setSelectedAddr(usdtAddr)
    setBitcoin(false)
    setEthcoin(false)
  }

  const ethActive = () => {
    setUsdtcoin(false)
    setBitcoin(false)
    setEthcoin(true)
    setSelectedAddr(ethAddr)
  }

  const btcActive = () => {
    setUsdtcoin(false)
    setBitcoin(true)
    setEthcoin(false)
    setSelectedAddr(btcAddr)
  }

  return (
    <div className='fund'>
      <div className='fund-header'>
        FUND YOUR ACCOUNT
      </div>
      <div className='select-coin'>
        <p className='select-header'>Choose payment method from the list below, copy the company's wallet address and fund your account</p>

        <div className='coins-container'>

          <p className="amount">Amount</p>
          <input className="amount-input" type="number" name="" id="" />

          <div onClick={usdtActive} className={usdtcoin ? 'fund-coin coin-active' : 'fund-coin'}>
            <img src={usdt} alt="" />
            <p>USDT</p>
          </div>
          <div onClick={ethActive} className={ethcoin ? 'fund-coin coin-active' : 'fund-coin'}>
            <img src={eth} alt="" />
            <p>Ethereum</p>
          </div>
          <div onClick={btcActive} className={bitcoin ? 'fund-coin coin-active' : 'fund-coin'}>
            <img src={btc} alt="" />
            <p>Bitcoin</p>
          </div>
          <input className="coin-address" type="text" value={usdtcoin ? usdtAddr : ethcoin ? ethAddr : bitcoin ? btcAddr : ''} id="" />
          <button className="copy btn" onClick={copy}>Copy</button>
          <p className={copied ? 'copied' : 'collapse'}>copied!</p>

          <p className="upload-sub">Upload screenshot of payment reciept for confirmation</p>
          <input onChange={setSS} className="upload" type="file" name="" id="upload" />
          <button className='fund-btn btn' onClick={fund}>COMPLETE DEPOSIT</button>
        </div>
      </div>
    </div>
  )
}

export default Fund
