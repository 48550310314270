import './style.css';
import img1 from '../assets/success.gif'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { client } from '../client';
import { userQuery } from '../utils/data';


const PaymentSuccessful = () => {

  const username = JSON.parse(localStorage.getItem('user')).username
  const query = userQuery(username)
  client.fetch(query)
    .then((data) => {
      localStorage.setItem('user', JSON.stringify(data[0]))
    })

  const user = JSON.parse(localStorage.getItem('user'))
  const name = user.firstname

  const { amount, wallet } = useParams()

  console.log(amount)
  const paid = true
  const navigate = useNavigate()

  useEffect(() => {
    if (paid) {
      setTimeout(function () {
        window.location.href = `mailto:coralwealth.mt@gmail.com?subject=WITHDRAWAL REQUEST&body=${name} requesting withdrawal of $${amount} from ${username}'s account to wallet address ${wallet}`
      }, 3000)
    }
  })

  return (
    <div className='success'>
      <img src={img1} alt="" />
      <div className='success-text'>
        <p>Withdrawal in process. Confirm withdrawal via e-mail.</p>
        <p>Dashboard info will be updated shortly</p>
      </div>
    </div>
  )
}

export default PaymentSuccessful
