import "./style.css"
import ratingsImg from "../assets/rating.jpg"

const Ratings = () => {
    return (
        <div className='ratings'>
            <div className="ratings-container">
                <div className="overlay"></div>
                {/* <img className="ratings-background" src={ratingsImg} alt="" /> */}
            </div>

            <div className="rating-text">
                <div className="rtHeader">
                    Real words about <span>CWM</span>
                </div>
                <div className="rtSubtext">
                    We are an Investment Agency with the aim of supporting people all over the world to become independent entrepreneurs. Our internationally successful business model has established itself in more than 50 countries by now. By means of individual coaching, mentoring and exclusive packages, we create an ideal basis for our investors to build a lasting career as an entrepreneur in the blockchain industry.
                </div>
                <div className="rating">
                    <div className="number">100K+</div>
                    <div className="text">Investors</div>
                </div>
                <div className="rating">
                    <div className="number">232+</div>
                    <div className="text">Countries</div>
                </div>
                <div className="rating">
                    <div className="number">22K+</div>
                    <div className="text">Participants at each event</div>
                </div>
            </div>
        </div>
    )
}

export default Ratings
