import React from 'react'

const Invest = () => {
    return (
        <div className='invest-container'>
            <div className='invest-header'>
                INVEST WITH US
            </div>
            <div className='invest-subtext'>
                We are an Investment Agency with the aim of supporting people all over the world to become independent entrepreneurs. At Coral Wealth Management we place a high value on further education and the personal growth of all our investors. Flat hierarchies and regular feedback sessions ensure that our international team feels good at work and that everyone can bring forward their own ideas, thus creating for a better experience for our investors.
            </div>
            <div className='invest-subtext'>
                Mutual respect is very important for us; therefore, we always have a sympathetic ear for each other. Since we do not want to slow down anyone in their creativity and effort. Our passion and enthusiasm for what we do is reflected in our performance
            </div>
        </div>
    )
}

export default Invest
