import "./style.css";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Whatsapp = () => {
    return (
        <div className="whatsapp">
            <a href="https://wa.me/+115485808188"><WhatsAppIcon style={{ fontSize: '3rem', color: 'green' }} /></a>
        </div>
    )
}

export default Whatsapp
