import './style.css'

const FourZeroFour = () => {
  return (
    <div className='fourofour'>
      <p>Error 404: PAGE NOT FOUND</p>
    </div>
  )
}

export default FourZeroFour
