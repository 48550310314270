import React from 'react'
import Announcement from './Announcement'
import "./style.css"
import Menu from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';
import logo from "../assets/logo.png"
import { Link, useLocation } from "react-router-dom";
import { userQuery } from '../utils/data';
import { client } from '../client';
import { useEffect } from 'react';

const LoggedNavbar = () => {

    const location = useLocation();

    const [collapse, setCollapse] = useState(true)
    
    const [dbActive, setDbActive] = useState(false)
    const [helpActive, setHelpActive] = useState(false)
    const [fundActive, setFundActive] = useState(false)
    const [rwActive, setRwActive] = useState(false)
    const [planActive, setPlanActive] = useState(false)
    const [faqActive, setFaqActive] = useState(false)

    useEffect(() => {
        if(location.pathname == '/user/dashboard'){
            setDbActive(true)
        } else if(location.pathname == '/user/fund'){
            setFundActive(true)
        } else if(location.pathname == '/user/request-withdrawal') {
            setRwActive(true)
        } else if(location.pathname == '/user/subscribe') {
           setPlanActive(true) 
        } else if(location.pathname == '/user/support') {
            setHelpActive(true)
        } else if(location.pathname == '/user/faq') {
            setFaqActive(true)
        }
    })
    
    
    
    
    const toggleCollapse = () => {
        if (collapse) {
            setCollapse(false)
        } else {
            setCollapse(true)
        }
    }

    // const username = JSON.parse(localStorage.getItem('user')).username
    // const query = (userQuery(username))

    // client.fetch(query)
    //     .then((data) => {
    //         localStorage.setItem('user', JSON.stringify(data[0]))
    //     })

    // const user = JSON.parse(localStorage.getItem('user'))

    const clearUser = () => {
        localStorage.clear()
    }

    const collapseClasses = "collapse"

    return (
        <div className='navbar'>
            <Announcement />
            <div className='mobile-navbar'>
                <a href="/">
                    <div className='logoContainer'>
                        <img className='logo' src={logo} alt="" />
                        <span>CWM</span>
                    </div>
                </a>

                <div className='hamburger' onClick={() => toggleCollapse()}>
                    {collapse ? <Menu className='menu-icon' /> : <ClearIcon className='menu-icon' />}
                </div>
            </div>
            <div className={collapse ? collapseClasses : "menu"}>
                {/* <p className='nav-name'>Hello {user.firstname}</p>
                <hr /> */}
                <div className={dbActive ? 'menuItem active-menu' : 'menuItem'}><a href="/user/dashboard">DASHBOARD</a></div>
                <div className={fundActive ? 'menuItem active-menu' : 'menuItem'}><a href="/user/fund">FUND YOUR ACCOUNT</a></div>
                <div className={rwActive ? 'menuItem active-menu' : 'menuItem'}><a href="/user/request-withdrawal">REQUEST WITHDRAWAL</a></div>
                <div className={planActive ? 'menuItem active-menu' : 'menuItem'}><a href="/user/subscribe">SELECT PLAN</a></div>
                <div className={helpActive ? 'menuItem active-menu' : 'menuItem'}><a href="/user/support">HELP/SUPPORT</a></div>
                <div className={faqActive ? 'menuItem active-menu' : 'menuItem'}><a href="/user/faq">FAQs</a></div>
                {/* <div className='menuItem'>TERMS AND CONDITIONS</div> */}
                <div className='user'>
                    <button className='userBtn' onClick={clearUser}><a href="/">LOGOUT</a></button>
                </div>
                <p className='nav-copyright'>© 2023 CWM. All Rights Reserved</p>
            </div>
            <div className='desktop-navbar'>
                <div className='dm-logoContainer'>
                    <img className='logo' src={logo} alt="" />
                </div>
                <div className='desktop-menu'>
                    <div className='dm-item'>HOME</div>
                    <div className='dm-item'>ABOUT US</div>
                    <div className='dm-item'>SERVICES</div>
                    <div className='dm-item'>FAQs</div>
                    {/* <div className='dm-item'>TERMS AND CONDITIONS</div> */}
                </div>
                <div className='dn-user'>
                    <button className='dmBtn'>SIGN IN</button>
                    <button className='dmBtn'>SIGN UP</button>
                </div>
            </div>
        </div>
    )
}

export default LoggedNavbar
