import { useState } from "react";
import "./style.css";
import { client } from "../client";
import { useNavigate } from "react-router-dom";
import { userQuery } from "../utils/data";

const SignUp = () => {

  const navigate = useNavigate();

  const [surname, setSurname] = useState('')
  const [firstname, setFirstname] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')


  const sName = (val) => {
    setSurname(val.target.value)
  }

  const fName = (val) => {
    setFirstname(val.target.value)
  }

  const uName = (val) => {
    setUsername(val.target.value)
  }

  const eMail = (val) => {
    setEmail(val.target.value)
  }

  const pass = (val) => {
    setPassword(val.target.value)
  }

  const [mailError, setMailError] = useState(false)
  const [passError, setPassError] = useState(false)
  const [fieldError, setFieldError] = useState(false)

  const signUp = () => {
    if (password.length <= 8) {
      setPassError(true)
      setMailError(false)
      setFieldError(false)
    } else if (surname && firstname && username && email && password) {
      const query = userQuery(email)
      client.fetch(query)
        .then((data) => {
          if (data.length != 0) {
            setMailError(true)
            setPassError(false)
            setFieldError(false)
          } else {
            const doc = {
              _id: username,
              _type: 'user',
              surname: surname,
              firstname: firstname,
              email: email,
              username: username,
              password: password
            }

            client.createIfNotExists(doc)
              .then(navigate('/login'))

          }
        })
    } else {
      setFieldError(true)
      setMailError(false)
      setPassError(false)
    }
  }




  return (
    <div className='login'>
      <div className="login-container">
        <p className="login-header">SIGN-UP</p>

        <div className="signUp-errors">
          <p className={mailError ? 'show-error' : ''}>E-mail or Username is already linked to an account</p>
          <p className={passError ? 'show-error' : ''}>Password too short</p>
          <p className={fieldError ? 'show-error' : ''}>Please fill all fields properly</p>
        </div>

        <p className="login-pass">Surname</p>
        <input className="lp-value" onChange={sName} id="surname" type="text" />

        <p className="login-pass">First Name</p>
        <input className="lp-value" onChange={fName} id="firstname" type="text" />

        <p className="login-pass">Username</p>
        <input className="lp-value" onChange={uName} id="username" type="text" />

        <p className="login-username">E-mail</p>
        <input className="lu-value" onChange={eMail} id="email" type="text" />

        <p className="login-pass">Password</p>
        <input className="" onChange={pass} id="password" type="text" />


        <button onClick={signUp}>Sign up</button>

        <p className="login-signin"><a href="/login">Login instead</a></p>

        <p className="copyright-footer">©2023 Coral Wealth Management. All Rights Reserved </p>
      </div>
    </div>
  )
}

export default SignUp