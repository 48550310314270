import "./style.css"

const ConfirmPassword = () => {
  return (
    <div className='confirm-password'>
      <div className="cp-container">
        <p>This is a secure area. Please confirm your password before continuing</p>
        <p className="pass"> Enter Password <span>*</span></p>
        <input type="number" />
        <button>CONFIRM</button>
      </div>
    </div>
  )
}

export default ConfirmPassword
