import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';


const Footer = () => {
    return (
        <div className='footer'>
            <p>Coral Wealth Management was designed to endure during turbulent times in order to provide everyone a sustainable future and perspective in an emerging market. We have been running as a private base for over a year before our beta testing began in mid 2019. Rest assured – safety of your capital takes highest priority.</p>
            <div className='social-media-container'>
                <FacebookOutlinedIcon style={{ fontSize: "3rem" }} />
            </div>
            <hr />

            <p className='copyright'>© 2023 CWM All Rights Reserved.</p>
        </div>
    )
}

export default Footer


