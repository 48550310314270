import "./style.css";
import binance from "../assets/binance.png";
import luno from "../assets/luno.png";
import coinbase from "../assets/coinbase.png";
import bitfinex from "../assets/bitfinex.png";
import paxful from "../assets/paxful.png";


const BuyCoins = () => {
    return (
        <div className='buy-container'>
            <div className='bc-header'>
                WHERE TO BUY COINS
            </div>
            <div className='coins-container'>
                <div className='coin'>
                    <div className="binance-logo">
                        <img src={binance} alt="" />
                    </div>
                    <div className="coin-text">
                        Click here to buy coins from Binance
                    </div>
                    <div className="coin-btn">
                        <button><a href="https://www.binance.com">BUY NOW</a></button>
                    </div>
                </div>
                <div className='coin'>
                    <div className="bitfinex-logo">
                        <img src={bitfinex} alt="" />
                    </div>
                    <div className="coin-text">
                        Click here to buy coins from Binance
                    </div>
                    <div className="coin-btn">
                        <button><a href="https://www.bitfinex.com">BUY NOW</a></button>
                    </div>
                </div>
                <div className='coin'>
                    <div className="coinbase-logo">
                        <img src={coinbase} alt="" />
                    </div>
                    <div className="coin-text">
                        Click here to buy coins from Coinbase
                    </div>
                    <div className="coin-btn">
                        <button><a href="https://www.coinbase.com">BUY NOW</a></button>
                    </div>
                </div>
                <div className='coin'>
                    <div className="paxful-logo">
                        <img src={paxful} alt="" />
                    </div>
                    <div className="coin-text">
                        Click here to buy coins from Paxful
                    </div>
                    <div className="coin-btn">
                        <button><a href="https://www.paxful.com">BUY NOW</a></button>
                    </div>
                </div>
                <div className='coin'>
                    <div className="luno-logo">
                        <img src={luno} alt="" />
                    </div>
                    <div className="coin-text">
                        Click here to buy coins from Luno
                    </div>
                    <div className="coin-btn">
                        <button><a href="https://www.luno.com">BUY NOW</a></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuyCoins
