import "./style.css";

const History = () => {
    return (
        <div className='history'>
            <p className="history-header">HISTORY</p>

            <div className="history-menu">
                <p>Deposits</p>
                <p>Withdrawals</p>
                <p>Trades</p>
                <p>Bonuses</p>
            </div>
            <div className="history-details">
                <p>You have no transaction history</p>
            </div>
        </div>
    )
}

export default History
